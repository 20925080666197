// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JTgiE{animation:JTgiE 2s ease .75s 1 forwards;height:16%;opacity:0;pointer-events:none;position:absolute;width:130%;z-index:1}@media (min-width:1024px){.JTgiE{width:84%}}@keyframes JTgiE{0%{opacity:0;transform:\"translate(-72%, -62%) scale(0.5)\",}to{opacity:1;transform:\"translate(-50%,-40%) scale(1)\",}}", "",{"version":3,"sources":["webpack://./src/components/Spotlight/spotlight.module.css"],"names":[],"mappings":"AAAA,OAOI,uCAA4C,CAL5C,UAAW,CAEX,SAAU,CAEV,mBAAoB,CALpB,iBAAkB,CAElB,UAAW,CAEX,SAGJ,CAEA,0BACI,OACI,SACJ,CACJ,CAEA,iBACI,GACI,SAAU,CACV,6CACJ,CAEA,GACI,SAAU,CACV,0CACJ,CACJ","sourcesContent":[".spotlight {\n    position: absolute;\n    height: 16%;\n    width: 130%;\n    opacity: 0;\n    z-index: 1;\n    pointer-events: none;\n    animation: spotlight 2s ease .75s 1 forwards;\n}\n\n@media (min-width: 1024px) {\n    .spotlight {\n        width: 84%;\n    }\n}\n\n@keyframes spotlight {\n    0% {\n        opacity: 0;\n        transform: \"translate(-72%, -62%) scale(0.5)\",\n    }\n\n    100% {\n        opacity: 1;\n        transform: \"translate(-50%,-40%) scale(1)\",\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spotlight": "JTgiE"
};
export default ___CSS_LOADER_EXPORT___;
